import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import BrowserRouter from "react-router-dom/BrowserRouter";
import "./i18n";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {store} from "./redux/store";
import history from "./history";
import { HelmetProvider } from "react-helmet-async";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
