import React from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function PromoModal({dispatch, dir, shouldShow, setModalVisible, cid}) {
  const { t } = useTranslation();
  // console.log(`PromoModal: ${shouldShow}`)
  // const [show, setShow] = useState(shouldShow);

  const handleClose = () => setModalVisible(false);
  // const handleShow = () => setModalVisible(true);

  return (
    <Modal
    show={shouldShow}
    onHide={handleClose}
    backdrop="static"
    keyboard={true}
    dir={dir}
    centered
  >
    <Modal.Header>
        <Modal.Title>{t("promoTitle")}</Modal.Title>
    </Modal.Header>
    <Modal.Body style={dir === "rtl" ? { textAlign: "right" } : {}}>
      <video autoPlay muted loop id="myVideo">
        <source src="/pinchi.mp4" type="video/mp4" />
      </video>
      {t("promoText")}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        {t("ImStillSearching")}
      </Button>
        <a href={`mailto:monitor@paapmpaapm.com?subject=${t("monitorSubject")}&body=${t("monitorBody")} ${cid}`}><Button variant="primary" onClick={handleClose}>{t("promoButton")}</Button></a>
    </Modal.Footer>
  </Modal>  )
}

const mapStateToProps = ({ ui }) => ({
  dir: ui.direction,
});

export default connect(mapStateToProps)(PromoModal);