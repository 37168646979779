import { all } from "redux-saga/effects";
import vendors from "./vendors/saga";
import locations from "./locations/saga";
import currencies from "./currencies/saga"

function* rootSaga() {
  yield all([vendors(), locations(), currencies()]);
}

export default rootSaga;
