import { all, takeLatest, call, put } from "redux-saga/effects";
import {
  getVendors, getVendorsError, setVendorStatus, setVendorsLoading
} from "./action";

import {
  getVendors as getVendorsQuery,
} from "./query";

export function* getVendorsSaga({ payload }) {
  try {
    // console.log("setVendorsLoading=true")
    yield put(setVendorsLoading(true))
    const data = yield call(getVendorsQuery, payload);

    yield put(setVendorsLoading(false));
    // console.log("setVendorsLoading=false")
    yield put(setVendorStatus(data));
  } catch (error) {
    yield put(getVendorsError({ error } ));
  }
}

export default function* projectManagerSaga() {
  yield all([takeLatest(getVendors, getVendorsSaga)]);
}
