import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";
import map from 'lodash/map';

import './styles.scss';
import Done from '../../assets/images/loadticks.gif';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';

const LoadingVendors = ({ dir, data={}, progress=0 }) => {
  const { t } = useTranslation();

  let dealsFound = 0;
  map(data, (item, key) => {
    if (item && item.data) {
      if (Array.isArray(item.data) && item.data.length) {
        // support subsearchers (not tested)
        if (Array.isArray(item.data[0])) {
          item.data.forEach(subIndex => {
            dealsFound += item.data[subIndex].length;
          })
        } else {
          // debugger;
          dealsFound += item.data.length;
        }
      }
    }
  })

  return (
    <div className="vendors-section rounded">
      <Row>
        <Col>
          <h2>{t('loadingResults')}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressBar animated variant="success" now={progress} label={`${progress}%`} />
        </Col>
      </Row>
      <Row>
        <Col className="item-wrapper">
          <CountUp end={dealsFound} preserveValue={true} duration={2} suffix={" " + t('dealsFound')}></CountUp>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {map(data, (item, key) => (
          <Col /*md={2} sm={3} xs={6}*/ xs={6} sm={'auto'} key={key} className="item-wrapper">
            {/*<img src={item.image} alt="" className={item.loaded ? '' : 'loading'} />*/}
            <div>{t(key)}</div>
            <div>
              {item.loaded
                ? <img src={Done} alt="" />
                : <Spinner animation="border" variant="primary" />
              }
            </div>
          </Col>
          )
        )}
      </Row>
    </div>
  )
};

export default LoadingVendors;
