import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { LOGROCKET_KEY, CLARITY_KEY } from '../../constants/default'
import LogRocket from '../LogRocket'
import Clarity from '../Clarity'
import useScript from '../useScript'
import { useLocation } from "react-router";
import Attributor from '../Attributor'


const TechFooter = ({ enableLogRocket = false }) => {
  const location = useLocation();

  // const rtl = dir === 'rtl';
  useScript("https://www.googletagmanager.com/gtag/js?id=G-SEK9X7V9L8");

  useEffect(() => {
    if (window.location.hostname !== "localhost" &&
      window.location.search.indexOf("ignore=true") === -1) {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      // gtag('config', 'AW-980636270'); // ads tag
      // gtag('event', 'conversion', { 'send_to': 'AW-980636270/wKn-CJOVoosBEO6kzdMD' }); // track gmail ad conversion
      // console.log("setup gtag");
      // gtag('config', 'UA-69206805-6', {
      //   send_page_view: false,
      //   cookie_flags: 'max-age=7200;secure;samesite=none',
      //   cookie_domain: '.paapmpaapm.com',
      // }); // analytics tag cookie_flags samesite=none means allow tracking when embeded in another site (i.e. iframe)
      // it is only important for our widget tracking
  
      gtag('config', 'G-SEK9X7V9L8', {
        // send_page_view: false,
        cookie_flags: 'max-age=7200;secure;samesite=none',
        cookie_domain: '.paapmpaapm.com',
      }); // analytics tag cookie_flags samesite=none means allow tracking when embeded in another site (i.e. iframe)
      // it is only important for our widget tracking

      // hack to allow gtag usage everywhere
      window.gtag = gtag;
    } else {
      console.log("ignore analytics");
    }
  }, [])

  useEffect(() => {
    if (window.gtag) {
      // once we drop UA we need to revisit and cleanup page views tracking
      // most likely we still have duplicated page views
      // console.log("track a pageview");
      // window.gtag('config', 'UA-69206805-6', {'page_path': location.pathname + location.search});
      window.gtag('config', 'G-SEK9X7V9L8', {'page_path': location.pathname + location.search});
    }
  }, [location.pathname]);

  return (
    <div className="footer-tech">
      {/* <!-- Facebook Pixel Code --> */}
      <img alt="fb tracking pixel" height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=1265622610167231&ev=PageView&noscript=1" />
      {enableLogRocket === "true" ?
        // <LogRocket creds={LOGROCKET_KEY} /> :
        <Clarity creds={CLARITY_KEY} /> :
        ''
      }
      {/* underlying set cookie won't work in iframe - so we are good with not tracking iframe views/spam*/}
      <Attributor/> 
      
    </div>
  );
}

const mapStateToProps = ({ vendors, results, ui }) => ({
});

export default connect(mapStateToProps)(TechFooter);