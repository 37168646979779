import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import { setCookie } from '../../utils/cookies';
import * as queryString from "query-string";

// underlying set cookie won't work in iframe - so we are good with not tracking iframe views/spam
const Attributor = () => {
  const location = useLocation();

  useEffect(() => {
    const params = queryString.parse(location.search);

    let campaign = params.utm_campaign ? params.utm_campaign : '';
    if (campaign) {
      setCookie('attributor', campaign, new Date(new Date().setDate(new Date().getDate() + 365 + 60)).toUTCString())

      // starting 01/08/23 we also set sessionStorage tracking to support tracking within an embeded full screen iframe
      // the thing with sessionStorage is that it is local to the containing tab and not shared. so imposible to abuse.
      window.sessionStorage.setItem('attributor', campaign)
    }
  }, [])


  return (
    <></>
  );
}

export default Attributor