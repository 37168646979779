import { handleActions } from 'redux-actions';
import {
  getResultsError,
  getResultsSuccess, setResultsLoaded,
} from './action';

const DEFAULT_STATE = {
  count: 0,
  data: [],
  loaded: false,
  lastCheck: Date.now(),
};

const handlers = {
  [getResultsSuccess]: (state, action) => {
    return {
      ...state,
      data: action.payload ? action.payload : [],
      count: action.payload ? action.payload.length : 0,
      loaded: true,
      lastCheck: Date.now(),
    }
  },
  [setResultsLoaded]: (state, action) => {
    return {
      ...state,
      loaded: action.payload,
      lastCheck: Date.now(),
    }
  },
  [getResultsError]: (state, action) => {
    return {
      ...state,
      loaded: true,
      lastCheck: Date.now(),
    }
  },
};

export default handleActions(handlers, DEFAULT_STATE);
