import { handleActions } from 'redux-actions';
// import { find, forEach } from 'lodash';
import find from 'lodash/find';
import forEach from 'lodash/forEach';

import {
  getVendorsSuccess, setVendorsLoaded, setVendorStatus, setVendorsLoading,
} from './action';

const DEFAULT_STATE = {
  count: 0,
  data: {},
  loaded: false,
  loading: false,
  lastCheck: Date.now(),
};

const handlers = {
  [getVendorsSuccess]: (state, action) => {
    return {
      ...state,
      data: action.payload ? action.payload.map(item => ({ ...item, loaded: false })) : [],
      count: action.payload ? action.payload.length : 0,
    }
  },
  [setVendorsLoaded]: (state, action) => {
    // console.log('in setVendorsLoaded', action);
    return {
      ...state,
      loaded: action.payload,
      data: action.payload ? state.data : {},
    }
  },
  [setVendorsLoading]: (state, action) => {
    // console.log('in setVendorsLoading', action);
    return {
      ...state,
      loading: action.payload,
    }
  },
  [setVendorStatus]: (state, action) => {
    const data = {...state.data};

    forEach(action.payload, (item, key) => {
      if (!data[key]) {
        data[key] = {};
      }

      // mark which ones are done loading
      // support subSearchers and check if any of them is also null
      if (Array.isArray(item)) {
        data[key].loaded = true;
        if (item.findIndex(subSearch => subSearch == null) > -1) {
          data[key].loaded = false;
        }
      } else {
        data[key].loaded = item !== null;
      }

      // process 
      // support subSearchers and flatten sub lists into one
      if (Array.isArray(item)) {
        data[key].data = [];
        item.forEach(subSearch => {
          data[key].data = data[key].data.concat(subSearch);
        })
      }
    });

    const isNotLoaded = find(data, item => !item.loaded)

    return {
      ...state,
      data,
      loaded: !isNotLoaded,
      lastCheck: Date.now(),
    }
  },
};

export default handleActions(handlers, DEFAULT_STATE);
