import { handleActions } from 'redux-actions';
// import { find, forEach } from 'lodash';

import {
  getLocationsSuccess, setLocationsLoaded, setLocationStatus, setLocationsLoading,
} from './action';

const DEFAULT_STATE = {
  count: 0,
  data: [],
  loaded: false,
  loading: false,
};

const handlers = {
  [getLocationsSuccess]: (state, action) => {
    return {
      ...state,
      data: action.payload ? action.payload.map(item => ({ ...item, loaded: false })) : [],
      count: action.payload ? action.payload.length : 0
    }
  },
  [setLocationsLoaded]: (state, action) => {
    return {
      ...state,
      loaded: action.payload,
      data: action.payload ? state.data : []
    }
  },
  [setLocationsLoading]: (state, action) => {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [setLocationStatus]: (state, action) => {
    const data = action.payload;
    // debugger;

    // forEach(action.payload, (item, key) => {
    //   // if (!data[key]) {
    //   //   data[key] = {};
    //   // }
    //   // data[key].loaded = item !== null;
    //   // debugger;
    //   data[key] = item;
    // });

    // const isNotLoaded = find(data, item => !item.loaded)

    return {
      ...state,
      data,
      // loaded: !isNotLoaded
    }
  },
};

export default handleActions(handlers, DEFAULT_STATE);
