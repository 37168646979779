import { all, takeLatest, call, put } from "redux-saga/effects";
import {
  getLocations, getLocationsError, setLocationStatus, setLocationsLoading
} from "./action";

import {
  getLocations as getLocationsQuery,
} from "./query";

export function* getLocationsSaga({ payload }) {
  try {
    yield put(setLocationsLoading(true))
    const data = yield call(getLocationsQuery, payload);

    yield put(setLocationsLoading(false));
    yield put(setLocationStatus(data));
  } catch (error) {
    yield put(getLocationsError({ error } ));
  }
}

export default function* projectManagerSaga() {
  yield all([takeLatest(getLocations, getLocationsSaga)]);
}
