import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locale/en.json';
import he from './locale/he.json';
import ar from './locale/ar.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  he: {
    translation: he
  },
  ar: {
    translation: ar
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    whitelist: ['he', 'en', 'ar'],
    nonExplicitWhitelist: true,
    // load: 'languageOnly',
    resources,
    // lng: "he",
    fallbackLng: 'en',
    // debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    detection: {
      // order and from where user language should be detected
      order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag',  'subdomain'],
    
      // keys or params to lookup language from
      // lookupQuerystring: 'lng',
      // lookupCookie: 'i18next',
      // lookupLocalStorage: 'i18nextLng',
      // lookupSessionStorage: 'i18nextLng',
      // lookupFromPathIndex: 0,
      // lookupFromSubdomainIndex: 0,
    
      // cache user language on
      // caches: ['localStorage', 'cookie'],
      // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    
      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: 'myDomain',
    
      // optional htmlTag with lang attribute, the default is:
      // htmlTag: document.documentElement,
    
      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      // cookieOptions: { path: '/', sameSite: 'strict' }
    }
  });

export default i18n;