import { useState, useEffect } from "react";
import { clarity } from "react-microsoft-clarity";

const Clarity = ({ creds }) => {
  const [credentials, setCredentials] = useState(creds);

  useEffect(() => {
    if (
      window.location.hostname !== "localhost" &&
      window.location.search.indexOf("ignore=true") === -1 &&
      navigator.userAgent.indexOf("iPhone") === -1
    ) {
      clarity.init(credentials);
    }
  }, []);

  return null
};

export default Clarity;
