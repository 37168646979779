import { handleActions } from 'redux-actions';
// import { find, forEach } from 'lodash';

import {
  getCurrenciesSuccess, setCurrenciesLoaded, setCurrenciesStatus, setCurrenciesLoading,
} from './action';

const DEFAULT_STATE = {
  count: 0,
  data: [],
  loaded: false,
  loading: false,
};

const handlers = {
  [getCurrenciesSuccess]: (state, action) => {
    return {
      ...state,
      data: action.payload ? action.payload.map(item => ({ ...item, loaded: false })) : [],
      count: action.payload ? action.payload.length : 0
    }
  },
  [setCurrenciesLoaded]: (state, action) => {
    return {
      ...state,
      loaded: action.payload,
      data: action.payload ? state.data : []
    }
  },
  [setCurrenciesLoading]: (state, action) => {
    return {
      ...state,
      loading: action.payload,
    }
  },
  [setCurrenciesStatus]: (state, action) => {
    const data = action.payload;
    // debugger;

    return {
      ...state,
      data,
    }
  },
};

export default handleActions(handlers, DEFAULT_STATE);
