import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import * as queryString from "query-string";
import { useLocation } from "react-router";
import SearchCarWidget from "../components/SearchCarWidget";
import routes from '../constants/routes.json';
import { DEFAULT_FILTERS } from '../constants/default';
import { useTranslation } from 'react-i18next';

function WidgetOnly({ dir }) {
  const location = useLocation();
  const [params, setParams] = useState();
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const lng = i18n.language.substring(0, 2)

  useEffect(() => {
    const params = queryString.parse(location.search);
    const { endDate, startDate, fromLocationCode, toLocationCode, age, title, subtitle, btnBgColor, btnBorderColor, widgetBgColor, btnTextColor, utm_campaign, ...rest } = params;
    setParams({ endDate, startDate, fromLocationCode, toLocationCode, age, title, subtitle, btnBgColor, btnBorderColor, widgetBgColor, btnTextColor, utm_campaign});
  }, []); // eslint-disable-line

  const onSubmit = fields => {
    const searchParams = {
      startDate: `${format(fields.startDate, 'dd/MM/yyyy')}T${fields.hoursFrom}:${fields.minsFrom}`,
      endDate: `${format(fields.endDate, 'dd/MM/yyyy')}T${fields.hoursTo}:${fields.minsTo}`,
      fromLocationCode: fields.fromLocation.locationCode,
      toLocationCode: fields.toLocation.locationCode,
      age: fields.age,
      lng: lng,
      ...DEFAULT_FILTERS,
    };

    if (params.utm_campaign) {
      searchParams.utm_campaign = params.utm_campaign
    }

    const url = 'https://search.paapmpaapm.com/' + routes.SEARCH + '?' + queryString.stringify(searchParams);
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  return (
    <>
      <SearchCarWidget dir={dir} onSubmit={onSubmit} params={params} minimized={false} allowTitleOverride={true} forcePortal={true} showTitles={true} />
    </>
  );
}

const mapStateToProps = ({ ui }) => ({
  dir: ui.direction,
});

export default connect(mapStateToProps)(WidgetOnly);
