// import axios from "axios";
import { BACKEND_API_ENDPOINT } from '../../constants/default';
import axios from "axios/index";

// const headers = {};

export const getCurrencies = ({ query }) => {
  // const authHeader = {};

  return axios.get(
    `${BACKEND_API_ENDPOINT}/currencies`,
    // {headers: {...headers, ...authHeader}}
  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('getCurrencies error', error);
      return {success: false, error};
    });
};
