import React from "react";
import Button from "react-bootstrap/Button";
// import "./styles.scss";
import { useTranslation } from "react-i18next";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css"; // import
import { getCookie } from '../../utils/cookies';

const Tooltips = ({ dir, isAirport }) => {
  const { t } = useTranslation();
  const attributor = window.sessionStorage.getItem("attributor") ? window.sessionStorage.getItem("attributor") : getCookie('attributor')

  const trackTooltip = (evt) => {
    // console.log('tracking tooltip event ' + evt.feature)
    if (window.gtag) {
      window.gtag('event', 'Tooltip', {
        'event_category': 'Engagement',
        'event_label': evt.feature,
        // 'value': count,
      })
    }
  };

  return (
    // tooltips for results
    <div>
      <Tooltip
        anchorSelect=".oneWayIncluded"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "oneWayIncluded" })}}
      >
        <b>{t("oneWayIncludedDescTitle")}</b>
        <br />
        {t("oneWayIncludedDesc")}
        <br />
      </Tooltip>
      <Tooltip
        anchorSelect=".checkOneWayFee"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "checkOneWayFee" })}}
      >
        <b>{t("checkOneWayFeeDescTitle")}</b>
        <br />
        {t("checkOneWayFeeDesc")}
        <br />
      </Tooltip>
      <Tooltip
        anchorSelect=".zeroExcess"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "zeroExcess" })}}
      >
        <b>{t("zeroExcessDescTitle")}</b>
        <br />
        {t("zeroExcessDescPrefix")}
        <br />
        <ul>
          <li>{t("zeroExcessDesc1")}</li>
          <li>{t("zeroExcessDesc2")}</li>
          <li>{t("zeroExcessDesc3")}</li>
        </ul>
        {t("zeroExcessDescSuffix")}
        <br />
        <Button
          variant="primary"
          href="https://paapmpaapm.com/covers-explained.html"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
            <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
          </svg> {t("furtherReadingText")}
        </Button>
      </Tooltip>
      <Tooltip
        anchorSelect=".excessRefundIncluded"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "excessRefundIncluded" })}}
      >
        <b>{t("excessRefundIncludedDescTitle")}</b>
        <br />
        {t("excessRefundIncludedDescPrefix")}
        <br />
        <ul>
          <li>{t("excessRefundIncludedDesc1")}</li>
          <li>{t("excessRefundIncludedDesc2")}</li>
          <li>{t("excessRefundIncludedDesc3")}</li>
        </ul>
        {t("excessRefundIncludedDescSuffix")}
        <br />
        <Button
          variant="primary"
          href="https://paapmpaapm.com/covers-explained.html"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
            <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
          </svg> {t("furtherReadingText")}
        </Button>
      </Tooltip>
      <Tooltip
        anchorSelect=".mandatoryCoverageNotIncluded"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "mandatoryCoverageNotIncluded" })}}
      >
        <b>{t("mandatoryCoverageNotIncludedTitle")}</b>
        <br />
        {t("mandatoryCoverageNotIncludedPrefix")}
        <br />
        <ul>
          <li>{t("mandatoryCoverageNotIncludedDesc1")}</li>
          <li>{t("mandatoryCoverageNotIncludedDesc2")}</li>
        </ul>
        <br />
        <Button
          variant="primary"
          href="https://paapmpaapm.com/covers-explained.html"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
            <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
          </svg> {t("furtherReadingText")}
        </Button>
      </Tooltip>
      <Tooltip
        anchorSelect=".basicCoverageIncluded"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "basicCoverageIncluded" })}}
      >
        <b>{t("basicCoverageIncludedTitle")}</b>
        <br />
        {t("basicCoverageIncludedPrefix")}
        <br />
        <ul>
          <li>{t("basicCoverageIncludedDesc1")}</li>
          <li>{t("basicCoverageIncludedDesc2")}</li>
          <li>{t("basicCoverageIncludedDesc3")}</li>
        </ul>
        {t("basicCoverageIncludedDescSuffix")}
        <br />
        <Button
          variant="primary"
          href="https://paapmpaapm.com/covers-explained.html"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
            <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
          </svg> {t("furtherReadingText")}
        </Button>
      </Tooltip>
      <Tooltip
        anchorSelect=".tireAndGlassInsuranceIncluded"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "tireAndGlassInsuranceIncluded" })}}
      >
        <b>{t("tireAndGlassInsuranceIncludedTitle")}</b>
        <br />
        {t("tireAndGlassInsuranceIncludedDesc")}
        <br />
        <Button
          variant="primary"
          href="https://paapmpaapm.com/covers-explained.html"
          target="_blank"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
            <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
          </svg> {t("furtherReadingText")}
        </Button>
      </Tooltip>
      { /* acriss tooltips */}
      <Tooltip
        anchorSelect=".goodAcriss"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "goodAcriss" })}}
        render={({ content, activeAnchor }) => (
          <span>
          <b>{t("promisedGroupText")} {activeAnchor?.getAttribute('data-group')}</b>
          <br />
          {t("correctGroupText")} {activeAnchor?.getAttribute('data-model')} {t("andSimilarCarsText")}.<br />
          <br />
          <Button
            variant="primary"
            href={'https://paapmpaapm.com/cars-guide/' + activeAnchor?.getAttribute('data-model').toLowerCase() + '.html' + `${attributor ? '?utm_campaign=' + attributor : ''}`}
              target="_blank"
              rel="opener"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
              <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
            </svg> {t("furtherReadingText")}
          </Button>
          </span>
        )}
      />
      <Tooltip
        anchorSelect=".infoAcriss"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "infoAcriss" })}}
        render={({ content, activeAnchor }) => (
          <span>
          <b>{t("promisedGroupText")} {activeAnchor?.getAttribute('data-group')}</b>
          <br />
          {activeAnchor?.getAttribute('data-model')} {t("shouldBeInGroupText")} <strong>{activeAnchor?.getAttribute('data-correct-group')}</strong>.
          <br />
          {t("differentCarText")}.<br />
          <br />
          <Button
            variant="primary"
            href={'https://paapmpaapm.com/cars-guide/' + activeAnchor?.getAttribute('data-model').toLowerCase() + '.html' + `${attributor ? '?utm_campaign=' + attributor : ''}`}
              target="_blank"
              rel="opener"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
              <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
            </svg> {t("furtherReadingText")}
          </Button>
          </span>
        )}
      />
      <Tooltip
        anchorSelect=".badAcriss"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "badAcriss" })}}
        render={({ content, activeAnchor }) => (
          <span>
          <b>{t("promisedGroupText")} {activeAnchor?.getAttribute('data-group')}</b>
          <br />
          {activeAnchor?.getAttribute('data-model')} {t("shouldBeInGroupText")} <strong>{activeAnchor?.getAttribute('data-correct-group')}</strong>.
          <br />
          {t("smallerCarText")}.<br />
          <br />
          <Button
            variant="primary"
            href={'https://paapmpaapm.com/cars-guide/' + activeAnchor?.getAttribute('data-model').toLowerCase() + '.html' + `${attributor ? '?utm_campaign=' + attributor : ''}`}
              target="_blank"
              rel="opener"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
              <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
            </svg> {t("furtherReadingText")}
          </Button>
          </span>
        )}
      />
      <Tooltip
        anchorSelect="svg.deskLocation"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "deskLocation" })}}
        render={({ content, activeAnchor }) => (
          <span>
          <b>{t(activeAnchor?.getAttribute('data-desk-location'))}</b>
          <br />
          {t(activeAnchor?.getAttribute('data-desk-location') + 'Text')}<br />
          <br />
          <Button
            variant="primary"
            href={'https://paapmpaapm.com/locations-guide/' + activeAnchor?.getAttribute('data-location-slug') + '.html' + `${attributor ? '?utm_campaign=' + attributor : ''}`}
              target="_blank"
              rel="opener"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
              <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
            </svg> {t("furtherReadingText")}
          </Button>
          </span>
        )}
      />
      <Tooltip
        anchorSelect="svg.website"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "website" })}}
        render={({ content, activeAnchor }) => (
          <span>
          <b>{t(activeAnchor?.getAttribute('data-website'))}</b>
          <br />
          {t(activeAnchor?.getAttribute('data-website') + 'Text')}<br />
          <br />
          </span>
        )}
      />
      <Tooltip
        anchorSelect=".rating.badge"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "rating" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t(activeAnchor?.getAttribute('data-rating-string'))}</b>
            <br />
            {t('branchRatingIntro')}
            <br />
            <br />
            {`${t('basedOn')} ${activeAnchor?.getAttribute('data-rating-count')} ${t('googleReviewsText')}`}
            <br />
            <br />
            <Button
              variant="primary"
              href={`https://www.google.com/maps/search/?api=1&query=<address>&query_place_id=${activeAnchor?.getAttribute('data-place-id')}`}
              target="_blank"
              rel="opener"
              disabled={activeAnchor?.getAttribute('data-place-id') ? false : true}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
                <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
              </svg> {t("furtherReadingText")}
            </Button>
          </span>
        )}
      />
      <Tooltip
        anchorSelect="svg.no-rating"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "no-rating" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("noRating")}</b>
            <br />
            {t('noRatingText')}
            <br />
          </span>
        )}
      />

    { /* tooltips for results */ }
      <Tooltip
        anchorSelect="svg.insuranceFilter"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "insuranceFilter" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("insurance")}</b>
            <br />
            {t('insuranceText')}
            <br />
          </span>
        )}
      />

      <Tooltip
        anchorSelect="svg.payNowFilter"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "payNowFilter" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("payNow")}</b>
            <br />
            {t('payNowText')}
            <br />
          </span>
        )}
      />

<Tooltip
        anchorSelect="svg.localSupplierFilter"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "localSupplierFilter" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("localSupplier")}</b>
            <br />
            {t('localSupplierText')}
            <br />
          </span>
        )}
      />

<Tooltip
        anchorSelect="svg.cancellationTermsFilter"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "cancellationTermsFilter" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("cancellationTerms")}</b>
            <br />
            {t('cancellationTermsText')}
            <br />
          </span>
        )}
      />

<Tooltip
        anchorSelect="svg.deskLocationFilter"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "deskLocationFilter" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("deskLocation")}</b>
            <br />
            {t('deskLocationText')}
            <br />
            <br />
            <Button
            variant="primary"
            href={'https://paapmpaapm.com/locations-guide/' + activeAnchor?.getAttribute('data-location-slug') + '.html' + `${attributor ? '?utm_campaign=' + attributor : ''}`}
              target="_blank"
              rel="opener"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M7.364 3.5a.5.5 0 0 1 .5-.5H14.5A1.5 1.5 0 0 1 16 4.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 3 14.5V7.864a.5.5 0 1 1 1 0V14.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H7.864a.5.5 0 0 1-.5-.5z"/>
              <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h5a.5.5 0 0 1 0 1H1.707l8.147 8.146a.5.5 0 0 1-.708.708L1 1.707V5.5a.5.5 0 0 1-1 0v-5z"/>
            </svg> {t("furtherReadingText")}
          </Button>
          </span>
        )}
      />

<Tooltip
        anchorSelect="svg.mileageFilter"
        className="tooltip"
        clickable
        afterShow={() =>{trackTooltip({ feature: "mileageFilter" })}}
        render={({ content, activeAnchor }) => (
          <span>
            <b>{t("mileage")}</b>
            <br />
            {t('mileageText')}
            <br />
          </span>
        )}
      />


    </div>
  );
};

export default Tooltips;
