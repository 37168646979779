import { all, takeLatest, call, put } from "redux-saga/effects";
import {
  getCurrencies, getCurrenciesError, setCurrenciesStatus, setCurrenciesLoading
} from "./action";

import {
  getCurrencies as getCurrenciesQuery,
} from "./query";

export function* getCurrenciesSaga({ payload }) {
  try {
    yield put(setCurrenciesLoading(true))
    const data = yield call(getCurrenciesQuery, payload);

    yield put(setCurrenciesLoading(false));
    yield put(setCurrenciesStatus(data));
  } catch (error) {
    yield put(getCurrenciesError({ error } ));
  }
}

export default function* projectManagerSaga() {
  yield all([takeLatest(getCurrencies, getCurrenciesSaga)]);
}
