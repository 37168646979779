// import axios from "axios";
import { BACKEND_API_ENDPOINT } from '../../constants/default';
import axios from "axios/index";

// const headers = {};

export const getLocations = ({ query }) => {
  // const authHeader = {};

  return axios.get(
    `${BACKEND_API_ENDPOINT}/getLocations?${query}`,
    // {headers: {...headers, ...authHeader}}
  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('getLocations error', error);
      return {success: false, error};
    });
};
